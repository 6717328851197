@import url(https://fonts.googleapis.com/css?family=Noto+Sans:300,400,500,700,800);

@font-face {
  font-family: 'Akrobat';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Akrobat-SemiBold.eot') format('eot');
  src: url('./fonts/Akrobat-SemiBold.woff') format('woff');
  src: url('./fonts/Akrobat-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Akrobat';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Akrobat-Regular.eot') format('eot');
  src: url('./fonts/Akrobat-Regular.woff') format('woff');
  src: url('./fonts/Akrobat-Regular.woff2') format('woff2');
}

$green-text-color: #174341;

$body-bg: #ffffff; //#E5E5E5;
$font-family-base: 'Noto Sans';
$nav-link-color: #174341;
$navbar-light-color: #174341;
$navbar-light-active-color: $nav-link-color;
$navbar-light-hover-color: #eeb33d;

@import "~bootstrap/scss/bootstrap";

.navbar {
  .container {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.fosize-header {
  background-image: url("./img/bg-top.png");
  background-repeat: no-repeat;
  background-size: cover;
}

#fosize-container {

  @include media-breakpoint-up(md) {
    padding-top: 100px;
    padding-left: 100px;
    padding-right: 60px;
  }
  @include media-breakpoint-down(md) {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }
  color: white;

  /*  #phone-img-head-big {
      @include media-breakpoint-up(lg) {
        margin-top: 100px;
      }
      text-align: center;
      vertical-align: middle;
    }*/

  #phone-img-head-little {
    @include media-breakpoint-up(lg) {
      display: none;
    }
    text-align: center;
  }

  #fosize-brand {
    @include media-breakpoint-up(md) {
      font-size: 109px;
    }

    @include media-breakpoint-down(md) {
      font-size: 80px;
    }

    font-family: 'Akrobat';
    font-weight: 500;
    //transform: scale(0.5, 1.5);
  }

  h2 {
    font-weight: 400;
    @include media-breakpoint-up(md) {
      font-size: 40px;
      padding-bottom: 40px;
    }
    @include media-breakpoint-down(md) {
      font-size: 30px;
      padding-bottom: 20px;
    }


  }

  .head-text {
    @include media-breakpoint-up(md) {
      font-size: 28px;
    }
    @include media-breakpoint-down(md) {
      font-size: 20px;
    }

    font-weight: 300;
    padding-bottom: 50px;
  }
}

article {
  @include media-breakpoint-up(md) {
    padding-bottom: 30px;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: 10px;
  }

  img {
    max-width: 100%;
  }
}

#content {
  padding: 0px;

  background-color: white;

  .mainContentContainer {
    padding: 16px;
  }

  h3 {
    @include media-breakpoint-up(md) {
      padding-bottom: 30px;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 8px;
    }

    font-size: 22px;
    font-weight: 700;

  }

  h4 {
    font-weight: 700;
    font-size: 18px;
  }


  .topBlocks {
    padding-left: 10px;
    padding-right: 10px;
  }
}


.active-lang {
  color: $navbar-light-hover-color;
}

.nav-link {
  text-transform: uppercase;

  &:visited {
    color: #174341;
  }

  &:hover {
    color: $navbar-light-hover-color;
  }
}

.card {
  border: none;
  border-radius: 0px;

  .card-text {
    font-size: 18px;
    font-weight: 500;
    color: $green-text-color
  }

  .card-body {
    padding: 20px;
  }

  .card-img {
    width: 100px;
    height: 100px;
  }
}

.navbar {
  .hstack {
    gap: 10px;
  }

  .navbar-text {
    a {
      color: $navbar-light-color !important;

      &:hover {
        color: $navbar-light-hover-color !important;
      }
    }
  }
}

footer {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #3C3F41;
  color: #BBBBBB;

  h4 {
    color: white;
  }

  a {
    color: #BBBBBB;
    text-decoration: none;

    &:hover {
      color: #eeb33d;
      text-decoration: none;
    }
  }
}